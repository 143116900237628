import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import io from "socket.io-client";
import VideoPlayer from "./VideoPlayer";
import LiveStreamPlayer from "./LiveStreamPlayer";
const url = process.env.REACT_APP_APP_BACK_URL;
const stream_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
const socket = io.connect(url);

const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);

const LiveStreaming1 = () => {
  const [accessToken, setToken] = useState(null);
  //  const streamUrl ='https://dxtexrol4as7mb4ttgv6ea.vultr-sgp-4.vmsproxy.com:443/hls/b3b11ab8-47be-7ae1-5756-b2c4b1327348.m3u?authKey=11f34e37-9468-4a9b-8326-e457e05ff4f6&chunked&sessionID=39&hi';
  const [data, setData] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [streamUrl, setStreamUrl] = useState(null);
  const canvasRefs = useRef({}); // To store references to each canvas

  useEffect(() => {
    fetchToken();
    // Return a cleanup function to stop all streams on component unmount
    return () => {
      Object.values(canvasRefs.current).forEach((canvas) => {
        if (canvas && canvas.getContext) {
          const context = canvas.getContext("2d");
          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
          }
        }
      });
    };
  }, []);
  const fetchData = async (accToken) => {
    try {
      const response = await fetch(
        `${url}/api/cameras/get/updated/list/${accToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.success) {
        setIsDisplay(true);
        setData(json.data);
        setIsLoading(false);
        // Initialize video streams
        // json.data.forEach((camera) => {
        //   setupStream(camera.id);
        // });
      } else {
        setIsNotFound(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchToken = async () => {
    try {
      const response = await fetch(`${url}/api/token`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.success) {
        setToken(json.token);
        await fetchData(json.token);
        // fetchStream("b3b11ab8-47be-7ae1-5756-b2c4b1327348", json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchStream = async (id, accToken) => {
    try {
      const response = await fetch(
        `${url}/api/cameras/get/camera/stream/${id}/${accToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        setStreamUrl(json.url);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              {/* <div className="col col-sidebar"></div> */}
              <div className="p-4">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row mt-5">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Live Streaming</h3>
                            <p>
                              View all the cameras installed in the facility
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>

                    <div className="stream-wrap">
                      {/* {streamUrl && <LiveStreamPlayer streamUrl={streamUrl} />} */}
                      {isLoading && <Loader />}
                      <div className="row justify-content-start mb-4" style={{ rowGap: "40px" }}>
                        {data.map((camera, index) => (
                          <>
                            {camera.status === "Online" && (
                              <div className="col-lg-4 col-md-4">
                                <div className="vide-wrap">
                                  {accessToken && (
                                    <LiveStreamPlayer
                                      accessToken={accessToken}
                                      cameraId={camera.id}
                                      cameraStatus={camera.status}
                                    />
                                  )}
                                  <div className="top-video-detail">
                                    <p className="mb-0">
                                      {/* {camera.name}{" "} */}
                                     
                                    </p>
                                  </div>
                                  {/* <div className="bottom-video-detail">
                                  <p className="mb-0">
                                    {" "}
                                    {camera.id ? `(${camera.id})` : ""}
                                  </p>
                                </div> */}
                                </div>
                              </div>
                            )}
                          </>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiveStreaming1;
