
import React from 'react';
import WebRTCPlayer from './WebRTC';
import styles from "./style.module.css";
const WebR = () => {
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              {/* <div className="col col-sidebar"></div> */}
              <div className="p-4">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row mt-5">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Live Streaming</h3>
                            <p>
                              View all the cameras installed in the facility
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                  
                    <div className="stream-wrap">
                      {/* <VideoPlayer src={hlsStreamUrl} /> */}
                      <WebRTCPlayer serverIp="192.168.18.21" port="7001" cameraId="19e219d0-d51a-1faa-329b-87cef7f63660" />
                      {/* <VideoPlayer src={hlsStreamUrl} /> */}
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  
  );
};

export default WebR;

