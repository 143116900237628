import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
const url = process.env.REACT_APP_APP_BACK_URL;
const Loader = () => (
  <div className={styles.loader1}>
    <div className={styles.spinner}></div>
    {/* <p>Loading...</p> */}
  </div>
);
const LiveStreamPlayer = ({ accessToken, cameraId, cameraStatus }) => {
  const videoRef = useRef(null); // Hidden video element for streaming
  const canvasRef = useRef(null); // Canvas element for drawing video frames
  const [isPlaying, setIsPlaying] = useState(
    JSON.parse(localStorage.getItem("isPlaying")) || false
  ); // Load initial playback state from localStorage
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // if (isPlaying) {
    fetchStream(cameraId, accessToken);
    // }

    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, [accessToken, cameraId, isPlaying]);

  const fetchStream = async (id, accToken) => {
    try {
      const response = await fetch(
        `${url}/api/cameras/get/camera/stream/${id}/${accToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const json = await response.json();
      if (json.success) {
        const videoElement = videoRef.current;
        const canvasElement = canvasRef.current;
        const ctx = canvasElement.getContext("2d");

        if (!videoElement || !ctx) return;

        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(json.url);
          hls.attachMedia(videoElement);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoElement.play().catch((error) => {
              setIsLoading(false);
              console.error("Error playing video:", error);
            });
            drawFrame(videoElement, ctx, canvasElement);
            setIsLoading(false);
          });

          hls.on(Hls.Events.ERROR, (event, data) => {
            setIsLoading(false);
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error("Network error. Retrying...");
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.error("Media error. Attempting recovery...");
                  hls.recoverMediaError();
                  break;
                default:
                  console.error(
                    "Unrecoverable error. Destroying HLS instance."
                  );
                  hls.destroy();
                  alert("Stream cannot be played. Please try again later.");
              }
            }
          });

          return () => hls.destroy();
        } else if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
          videoElement.src = json.url;
          videoElement.addEventListener("loadedmetadata", () => {
            videoElement.play().catch((error) => {
              console.error("Error playing video:", error);
            });
            drawFrame(videoElement, ctx, canvasElement);
          });
        } else {
          console.error(
            "HLS is not supported and video cannot be played natively."
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const drawFrame = (videoElement, ctx, canvasElement) => {
    const render = () => {
      // if (isPlaying) {
      ctx.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      requestAnimationFrame(render);
    };
    // };
    requestAnimationFrame(render);
  };

  const handlePlay = () => {
    setIsPlaying(true);
    localStorage.setItem("isPlaying", true); // Persist playback state to localStorage
  };

  return (
    <div className="mt-3">
      {/* Hidden video element for playing the stream */}
      {isLoading && <Loader />}
      <video ref={videoRef} style={{ display: "none" }} />
      <Link to={`/single/live/streaming/${cameraId}?token=${accessToken}`}>
        {/* Canvas for rendering the video frames */}
        <canvas
          ref={canvasRef}
          width="640"
          height="360"
          style={{ width: "100%", height: "100%" }}
        />
      </Link>

      {/* Button to start playback */}
      {/* {!isPlaying && cameraStatus === "Online" && (
        <button
          className="bg-blue  border border-2 border-blue rounded-pill me-2"
          onClick={handlePlay}
        >
          Start
        </button>
      )} */}
      {!isLoading && (
        <Link to={`/single/camera/footages/${cameraId}?token=${accessToken}`}>
          <button
            className="bg-blue  border border-2 border-blue rounded-pill  mt-2 mb-2"
            style={{ width: "100%" }}
          >
            Footages
          </button>
        </Link>
      )}
    </div>
  );
};

export default LiveStreamPlayer;
