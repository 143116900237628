import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LiveStreaming from "./components/LiveStreaming";
import LiveStreaming1 from "./components/LiveStreaming1";
import SingleLiveStream from './components/SingleLiveStream';
import LiveSingleStreamPlayer from './components/LiveSingleStreamPlayer';
import CameraFootage from './components/CameraFootage';
import WebR from './components/WebR';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/streaming"
            element={<LiveStreaming />}
          />
           <Route
            exact
            path="/"
            element={<LiveStreaming1 />}
          />
           <Route
            exact
            path="/web/rtc"
            element={<WebR />}
          />
          <Route
            exact
            path="/single/camera/streaming/:id"
            element={<SingleLiveStream />}
          />
           <Route
            exact
            path="/single/live/streaming/:id"
            element={<LiveSingleStreamPlayer />}
          />
           <Route
            exact
            path="/single/camera/footages/:id"
            element={<CameraFootage />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
// require('./components/Example');
