import React, { useEffect, useRef } from 'react';

const WebRTC = ({ serverIp, port, cameraId }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const wsUrl = `wss://${serverIp}:${port}/webrtc-tracker/?camera_id=${cameraId}`;
    const socket = new WebSocket(wsUrl);
    const peerConnection = new RTCPeerConnection();

    peerConnection.onicecandidate = event => {
      if (event.candidate) {
        socket.send(JSON.stringify({ action: "candidate", candidate: event.candidate }));
      }
    };

    peerConnection.ontrack = event => {
      videoRef.current.srcObject = event.streams[0];
    };

    socket.onopen = () => {
      peerConnection.createOffer()
        .then(offer => peerConnection.setLocalDescription(offer))
        .then(() => {
          socket.send(JSON.stringify({ action: "offer", sdp: peerConnection.localDescription }));
        });
    };

    socket.onmessage = event => {
      const message = JSON.parse(event.data);

      if (message.action === "answer") {
        const remoteDesc = new RTCSessionDescription(message.sdp);
        peerConnection.setRemoteDescription(remoteDesc);
      } else if (message.action === "candidate") {
        const candidate = new RTCIceCandidate(message.candidate);
        peerConnection.addIceCandidate(candidate);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
      peerConnection.close();
    };
  }, [serverIp, port, cameraId]);

  return (
    <video ref={videoRef} autoPlay controls style={{ width: '100%', height: 'auto' }}>
      Your browser does not support WebRTC.
    </video>
  );
};

export default WebRTC;
