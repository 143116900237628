import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      if (Hls.isSupported()) {
        // HLS.js is supported
        const hls = new Hls();

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (videoElement) {
            videoElement.play().catch(error => {
              console.error('Error attempting to play:', error);
            });
          }
        });

        hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            console.error('HLS.js error:', data.fatal, data);
          }
        });

        hls.loadSource(src);
        hls.attachMedia(videoElement);

        return () => {
          if (hls) {
            hls.destroy();
          }
        };
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        // For browsers that support HLS natively (e.g., Safari)
        videoElement.src = src;
        videoElement.addEventListener('loadedmetadata', () => {
          videoElement.play().catch(error => {
            console.error('Error attempting to play:', error);
          });
        });

        return () => {
          videoElement.removeEventListener('loadedmetadata', () => {});
        };
      } else {
        console.error('HLS is not supported on this browser.');
      }
    }
  }, [src]);

  return (
    <div>
      <video ref={videoRef} controls width="640" height="360">
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
