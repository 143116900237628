import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import io from "socket.io-client";

const url = process.env.REACT_APP_APP_BACK_URL;
const stream_url = process.env.REACT_APP_STREAMIN_SOCKET_URL;
const socket = io.connect(url);

const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);

const LiveStreaming = () => {
  const videoRef = useRef(null);

  const [data, setData] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const canvasRefs = useRef({}); // To store references to each canvas

  useEffect(() => {
    fetchData();
    // Return a cleanup function to stop all streams on component unmount
    return () => {
      Object.values(canvasRefs.current).forEach((canvas) => {
        if (canvas && canvas.getContext) {
          const context = canvas.getContext("2d");
          if (context) {
            context.clearRect(0, 0, canvas.width, canvas.height);
          }
        }
      });
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${url}/api/cameras/get/list`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.success) {

        setIsDisplay(true);
        setData(json.data);
        setIsLoading(false);
        // Initialize video streams
        json.data.forEach((camera) => {
          setupStream(camera.value);
        });
      } else {
        setIsNotFound(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setupStream = (cameraId) => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = async () => {
      /* eslint-disable no-undef */
      await loadPlayer({
        url: `${stream_url}/stream/${cameraId}`,
        canvas: canvasRefs.current[cameraId],
      });
      setIsLoading(false);
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
    };
  };

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              {/* <div className="col col-sidebar"></div> */}
              <div className="p-4">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row mt-5">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Live Streaming</h3>
                            <p>
                              View all the cameras installed in the facility
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    <div className="stream-wrap">
                      {isLoading && <Loader />}
                      <div className="row justify-content-start">
                        {data.map((camera, index) => (
                          <div className="col-lg-cust-5">
                            <Link
                              to={`/single/camera/streaming/${camera.value}`}
                            >
                              <div className="vide-wrap">
                                <canvas
                                  ref={(el) =>
                                    (canvasRefs.current[camera.value] = el)
                                  }
                                  id={`videoCanvas-${camera.value}`}
                                  style={{ width: "100%", height: "100%" }}
                                ></canvas>
                                <div className="top-video-detail">
                                  <p className="mb-0">
                                    {camera.name}{" "}
                                    <span className="defected-text">
                                      ({camera.channelStatus})
                                    </span>
                                  </p>
                                </div>
                                {/* <div className="bottom-video-detail">
                                    <p className="mb-0">09/07/2023 23:47</p>
                                  </div> */}
                              </div>
                            </Link>
                          </div>
                        ))}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiveStreaming;
