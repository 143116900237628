import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { Link, useParams } from "react-router-dom";
import styles from "./style.module.css";
import BackIcon from "../assets/icons/icon-back.svg";
const url = process.env.REACT_APP_APP_BACK_URL;
const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);
const LiveSingleStreamPlayer = () => {
  // alert(cameraId);
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("token");
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, [isPlaying]);
  useEffect(() => {
    fetchStream(id, accessToken);
    // Return a cleanup function to stop all streams on component unmount
  }, [accessToken, id]);
  const fetchStream = async (id, accToken) => {
    try {
      const response = await fetch(
        `${url}/api/cameras/get/camera/stream/${id}/${accToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        const videoElement = videoRef.current;

        if (!videoElement) return;

        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(json.url);
          hls.attachMedia(videoElement);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            setIsLoading(false);
            console.log("HLS Manifest parsed");
            if (isPlaying) {
              videoElement.play().catch((error) => {
                console.error("Error playing video:", error);
              });
            }
          });

          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.error("Network error. Retrying...");
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.error("Media error. Attempting recovery...");
                  hls.recoverMediaError();
                  break;
                default:
                  console.error(
                    "Unrecoverable error. Destroying HLS instance."
                  );
                  hls.destroy();
                  alert("Stream cannot be played. Please try again later.");
              }
            }
          });

          return () => hls.destroy();
        } else if (videoElement.canPlayType("application/vnd.apple.mpegurl")) {
          videoElement.src = json.url;
          videoElement.addEventListener("loadedmetadata", () => {
            if (isPlaying) {
              videoElement.play().catch((error) => {
                console.error("Error playing video:", error);
              });
            }
          });
        } else {
          console.error(
            "HLS is not supported and video cannot be played natively."
          );
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="general-dashboard p-4">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Live Streaming</h3>
                  <p>View Live Streaming </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <div className=" d-flex justify-content-center align-items-center mt-4">
          {isLoading && <Loader />}
          <div>
            <video
              ref={videoRef}
              // controls
              autoPlay
              style={{ width: "100%", height: "100%" }}
            />
            {/* {!isPlaying && <button onClick={handlePlay}>Play Video</button>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveSingleStreamPlayer;
