import React, { useEffect, useState } from "react";
import TestVideo from "../assets/video/demo.mp4";
import { Link, useParams } from "react-router-dom";
import styles from "./style.module.css";
import BackIcon from "../assets/icons/icon-back.svg";
const url = process.env.REACT_APP_STREAMIN_SOCKET_URL;

const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);

const SingleLiveStream = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = async () => {
      try {
        /* eslint-disable no-undef */
        await loadPlayer({
          url: `${url}/stream/${id}`, // Replace with your desired channel ID
          canvas: document.getElementById("canvas"),
        });
        setIsLoading(false);
        /* eslint-enable no-undef */
      } catch (error) {
        console.error("Error setting up player:", error);
        setIsLoading(false);
      }
    };

    script.onerror = () => {
      console.error("Error loading script");
      setIsLoading(false);
    };

    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [IDBIndex]);
  return (
    <div className="general-dashboard p-4">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Live Streaming</h3>
                  <p>View Live Streaming </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <div className=" d-flex justify-content-center align-items-center mt-4">
          {isLoading && <Loader />}
          <canvas
            id="canvas"
            style={{ width: "650px", height: "400px" }}
          ></canvas>
        </div>
      </div>
    </div>
  );
};

export default SingleLiveStream;
