import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./style.module.css";
import BackIcon from "../assets/icons/icon-back.svg";
const url = process.env.REACT_APP_APP_BACK_URL;
const steamUrl = process.env.REACT_APP_STREAMIN_URL;
const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.spinner}></div>
    <p>Loading...</p>
  </div>
);
const VideoPlayer = ({ startTimeMs, durationMs, streamId }) => {
  const videoRef = useRef(null);
  const username = "admin";
  const password = "Security9!2";
  const encodedCredentials = btoa(`${username}:${password}`);
  useEffect(() => {
    if (videoRef.current) {
      // Convert milliseconds to seconds for the video element
      const startTimeSeconds = startTimeMs / 1000;

      // Seek to the start time when the video is ready
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.currentTime = startTimeSeconds;

        // Play the video
        videoRef.current.play();

        // Set a timeout to stop the video after the duration
        setTimeout(() => {
          videoRef.current.pause();
        }, durationMs);
      };
    }
  }, [startTimeMs, durationMs]);

  return (
    <div>
      <video width="100%" height="100%" controls>
        <source
          // src={`http://192.168.18.16:7001/media/${streamId}.mkv`}
          src={`${steamUrl}/media/${streamId}.mkv?pos=${startTimeMs}&duration=${durationMs}`}
          type="video/mp4"
          headers={{
            Authorization: `Basic ${encodedCredentials}`,
          }}
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
const LiveSingleStreamPlayer = () => {
  // alert(cameraId);
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const accessToken = queryParams.get("token");
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {}, [isPlaying]);
  useEffect(() => {
    fetchStream(id, accessToken);
    // Return a cleanup function to stop all streams on component unmount
  }, [accessToken, id]);
  const fetchStream = async (id, accToken) => {
    try {
      const response = await fetch(
        `${url}/api/cameras/get/camera/footage/${id}/${accToken}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        setIsLoading(false);
        setData(json.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="general-dashboard p-4">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div>
                  <h3>Footages</h3>
                  <p>View Live Streaming Footages </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        <div className=" d-flex justify-content-center align-items-center mt-4">
          {isLoading && <Loader />}
          <div className="row justify-content-start">
            {data.map((camera, index) => (
              <div className="col-lg-4">
                <div className="vide-wrap">
                  {/* <video  controls  style={{ width: "200px", height: "100%" }}>
                          <source
                            src={`https://a50d245e-af6a-4f44-910a-0f3a7da495b6.vultr-sgp-4.vmsproxy.com/media/${id}.mkv?duration=${camera.startTimeMs}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video> */}
                  <VideoPlayer
                    startTimeMs={camera.startTimeMs}
                    durationMs={camera.durationMs}
                    streamId={id}
                  />
                  {/* <canvas
                    ref={videoRef}
                    style={{ width: "400px", height: "100%" }}
                  ></canvas> */}
                  <div className="top-video-detail">
                    <p className="mb-0">
                      {camera.startTimeMs}{" "}
                      {/* <span className="defected-text">
                        ({camera.startTimeMs})
                      </span> */}
                    </p>
                  </div>
                  {/* <div className="bottom-video-detail">
                                    <p className="mb-0">09/07/2023 23:47</p>
                                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveSingleStreamPlayer;
